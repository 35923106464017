import markdown from "markdown-it";
import { twMerge } from "tailwind-merge";
export function Markdown({
  children,
  lightLinks,
  className
}: {
  children: string;
  lightLinks?: boolean;
  className?: string;
}) {
  if (!children) {
    return null;
  }
  const html = md.render(children);
  return <div className={twMerge("unreset", lightLinks ? "light-link" : "", className)} dangerouslySetInnerHTML={{
    __html: html
  }} data-sentry-component="Markdown" data-sentry-source-file="markdown.tsx" />;
}
const md = markdown({
  breaks: true,
  linkify: true
});

// Remember the old renderer if overridden, or proxy to the default renderer.
const defaultLinkRender = md.renderer.rules.link_open;
md.renderer.rules.link_open = function (tokens, idx, options, env, self) {
  const origin = typeof window === "undefined" ? "https://mymemoryatlas.com" : window.location.origin;
  if (tokens[idx].attrGet("href")) {
    const url = new URL(tokens[idx].attrGet("href")!, origin);
    if (url.origin !== origin) {
      // Add a new `target` attribute, or replace the value of the existing one.
      tokens[idx].attrSet("target", "_blank");
      tokens[idx].attrSet("rel", "noopener");
    }
  }

  // Pass the token to the default renderer.
  if (defaultLinkRender) {
    return defaultLinkRender(tokens, idx, options, env, self);
  } else {
    // Use the default renderer if available.
    return self.renderToken(tokens, idx, options);
  }
};