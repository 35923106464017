import { SignInButton, SignedIn, SignedOut, UserButton } from "@clerk/nextjs";
import { Cog6ToothIcon, QuestionMarkCircleIcon } from "@heroicons/react/16/solid";
export default function AuthMenu({
  className
}: {
  className?: string;
}) {
  return <div className="inline-block" data-sentry-component="AuthMenu" data-sentry-source-file="authmenu.tsx">
      <SignedOut data-sentry-element="SignedOut" data-sentry-source-file="authmenu.tsx">
        <SignInButton data-sentry-element="SignInButton" data-sentry-source-file="authmenu.tsx" />
      </SignedOut>
      <SignedIn data-sentry-element="SignedIn" data-sentry-source-file="authmenu.tsx">
        <UserButton data-sentry-element="UserButton" data-sentry-source-file="authmenu.tsx">
          <UserButton.MenuItems data-sentry-element="unknown" data-sentry-source-file="authmenu.tsx">
            <UserButton.Action label="App Settings" labelIcon={<Cog6ToothIcon className="h-4 w-4" />} open="settings" data-sentry-element="unknown" data-sentry-source-file="authmenu.tsx">
              Settings
            </UserButton.Action>
          </UserButton.MenuItems>
          <UserButton.UserProfilePage label="App Settings" labelIcon={<QuestionMarkCircleIcon className="h-4 w-4" />} url="settings" data-sentry-element="unknown" data-sentry-source-file="authmenu.tsx">
            <div>
              <h1>Settings Page</h1>
              <p>This is the custom settings page</p>
            </div>
          </UserButton.UserProfilePage>
        </UserButton>
      </SignedIn>
    </div>;
}
export const LoginIcon = ({
  ...props
}) => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" {...props} data-sentry-element="svg" data-sentry-component="LoginIcon" data-sentry-source-file="authmenu.tsx">
    <path d="M479.615-140.001V-200h268.076q4.616 0 8.463-3.846 3.846-3.847 3.846-8.463v-535.382q0-4.616-3.846-8.463-3.847-3.846-8.463-3.846H479.615v-59.999h268.076q30.308 0 51.308 21t21 51.308v535.382q0 30.308-21 51.308t-51.308 21H479.615Zm-54.229-169.232-41.537-43.383 97.384-97.385H140.001v-59.998h341.232l-97.384-97.385 41.537-43.383L596.154-480 425.386-309.233Z" data-sentry-element="path" data-sentry-source-file="authmenu.tsx" />
  </svg>;
export const AvatarIcon = ({
  ...props
}) => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" {...props} data-sentry-element="svg" data-sentry-component="AvatarIcon" data-sentry-source-file="authmenu.tsx">
    <path d="M240.924-268.307q51-37.846 111.115-59.769Q412.154-349.999 480-349.999t127.961 21.923q60.115 21.923 111.115 59.769 37.308-41 59.116-94.923Q800-417.154 800-480q0-133-93.5-226.5T480-800q-133 0-226.5 93.5T160-480q0 62.846 21.808 116.77 21.808 53.923 59.116 94.923Zm239.088-181.694q-54.781 0-92.396-37.603-37.615-37.604-37.615-92.384 0-54.781 37.603-92.396 37.604-37.615 92.384-37.615 54.781 0 92.396 37.603 37.615 37.604 37.615 92.384 0 54.781-37.603 92.396-37.604 37.615-92.384 37.615Zm-.012 350q-79.154 0-148.499-29.77-69.346-29.769-120.654-81.076-51.307-51.308-81.076-120.654-29.77-69.345-29.77-148.499t29.77-148.499q29.769-69.346 81.076-120.654 51.308-51.307 120.654-81.076 69.345-29.77 148.499-29.77t148.499 29.77q69.346 29.769 120.654 81.076 51.307 51.308 81.076 120.654 29.77 69.345 29.77 148.499t-29.77 148.499q-29.769 69.346-81.076 120.654-51.308 51.307-120.654 81.076-69.345 29.77-148.499 29.77ZM480-160q54.154 0 104.423-17.423 50.27-17.423 89.27-48.731-39-30.154-88.116-47Q536.462-290.001 480-290.001q-56.462 0-105.77 16.654-49.308 16.654-87.923 47.193 39 31.308 89.27 48.731Q425.846-160 480-160Zm0-349.999q29.846 0 49.924-20.077 20.077-20.078 20.077-49.924t-20.077-49.924Q509.846-650.001 480-650.001t-49.924 20.077Q409.999-609.846 409.999-580t20.077 49.924q20.078 20.077 49.924 20.077ZM480-580Zm0 355Z" data-sentry-element="path" data-sentry-source-file="authmenu.tsx" />
  </svg>;