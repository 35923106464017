"use client";

import { useAtom } from "jotai/react";
import { atom, getDefaultStore } from "jotai/vanilla";
import { ReactNode, useEffect } from "react";
import { initDeferredAtom } from "./loader";
export const hashParamsAtom = atom(new URLSearchParams(""));
export function updateHashParams(params: Record<string, string | null | undefined>) {
  const store = getDefaultStore();
  const newParams = new URLSearchParams(store.get(hashParamsAtom));
  for (const [key, value] of Object.entries(params)) {
    if (!value) {
      newParams.delete(key);
      continue;
    }
    newParams.set(key, value);
  }
  window.location.hash = "#" + newParams.toString();
}
export function singleHashParam(key: string) {
  return atom(get => {
    const params = get(hashParamsAtom);
    return params.get(key);
  }, (get, set, value: string | null) => {
    updateHashParams({
      [key]: value
    });
    const existing = get(hashParamsAtom);
    const newParams = new URLSearchParams(existing);
    if (!value) {
      newParams.delete(key);
    } else {
      newParams.set(key, value);
    }
    set(hashParamsAtom, newParams);
  });
}
export function WithHashParams({
  children
}: {
  children: ReactNode;
}) {
  const [hashParams, setHashParams] = useAtom(hashParamsAtom);
  useEffect(() => {
    function updateHashParams() {
      const hash = window.location.hash.substring(1);
      const params = new URLSearchParams(hash);
      if (params.toString() === hashParams.toString()) {
        return;
      }
      setHashParams(params);
    }
    updateHashParams();
    window.addEventListener("hashchange", updateHashParams);
    return () => {
      window.removeEventListener("hashchange", updateHashParams);
    };
  }, []);
  useEffect(() => {
    setTimeout(() => {
      initDeferredAtom();
    }, 1000);
  }, []);
  return <>{children}</>;
}