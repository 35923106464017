import React, { ElementType, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";
import { atom, getDefaultStore, WritableAtom } from "jotai/vanilla";
import { useAtom } from "jotai/react";
import { Markdown } from "./markdown";
import Link, { LinkProps } from "next/link";
export function Button({
  className,
  children,
  onClick,
  disabled,
  disabledReason,
  title,
  secondary,
  Icon,
  confirm,
  dangerous,
  ...props
}: {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void | Promise<void>;
  disabledReason?: string;
  secondary?: boolean;
  Icon?: ElementType<{
    className?: string | undefined;
  }>;
  confirm?: string;
  dangerous?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>) {
  const cls = twMerge("rounded-md border bg-blue-400 hover:bg-blue-700 px-2", disabled ? "opacity-50 cursor-not-allowed" : "", secondary ? "bg-gray-300 text-gray-700 border-gray-400 hover:bg-gray-400" : "", dangerous ? "bg-red-600 hover:bg-red-700 text-white" : "", className);
  const [error, setError] = useState<Error | null>(null);
  const [running, setRunning] = useState(false);
  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    if (disabled) {
      return;
    }
    if (confirm) {
      if (!window.confirm(confirm)) {
        return;
      }
    }
    let resp: any;
    try {
      resp = onClick(event);
      setError(null);
    } catch (e) {
      setError(e as Error);
      console.error("Error in handler:", e);
    }
    if (resp && resp.then) {
      setRunning(true);
      resp.catch((e: Error) => {
        setError(e);
      }).finally(() => {
        setRunning(false);
      });
    }
  }
  return <button onClick={handleClick} className={cls} disabled={disabled} title={disabled ? disabledReason || title : title} {...props} data-sentry-component="Button" data-sentry-source-file="input.tsx">
      {children}
      {Icon && <Icon className="h-4 w-4 inline-block" />}
      {running && <span className="ml-2">...</span>}
      {error && <div className="text-red-600 text-xs">{error.message}</div>}
    </button>;
}
export function CheckButton({
  className,
  atom,
  on,
  off,
  onClass,
  offClass
}: {
  className?: string;
  atom: WritableAtom<boolean, [boolean], void>;
  on: React.ReactNode;
  off: React.ReactNode;
  onClass?: string;
  offClass?: string;
}) {
  const [input, setInput] = useAtom(atom);
  onClass = onClass || "bg-green-600 border border-green-700 p-1";
  offClass = offClass || "bg-green-950 border border-green-700 p-1";
  const cls = twMerge(className, input ? onClass : offClass);
  return <Button className={cls} onClick={() => {
    setInput(!input);
  }} data-sentry-element="Button" data-sentry-component="CheckButton" data-sentry-source-file="input.tsx">
      {input ? on : off}
    </Button>;
}
type AProps = {
  blank?: boolean;
  className?: string;
  children: React.ReactNode;
} & LinkProps & React.AnchorHTMLAttributes<HTMLAnchorElement>;
export function A({
  blank,
  className,
  ...props
}: AProps) {
  const p = {
    ...props
  };
  const cls = twMerge("text-cyan-600 hover:underline", className);
  if (blank) {
    return <a {...p} className={cls} target="_blank" rel="noopener noreferrer"></a>;
  }
  return <Link className={cls} {...p} data-sentry-element="Link" data-sentry-component="A" data-sentry-source-file="input.tsx" />;
}
export function ButtonLink({
  blank,
  className,
  ...props
}: AProps) {
  return <A {...props} className={twMerge("rounded-md border bg-blue-400 hover:bg-blue-700 px-2 py-1",
  // Reset some of A's styles:
  "text-black hover:no-underline", className)} blank={blank} data-sentry-element="A" data-sentry-component="ButtonLink" data-sentry-source-file="input.tsx" />;
}
export function TextInput({
  atom: inputAtom,
  onEscape,
  onEnter,
  className,
  ...props
}: {
  atom: WritableAtom<string, [string], void>;
  onEscape?: () => void;
  onEnter?: () => void;
  className?: string;
} & React.InputHTMLAttributes<HTMLInputElement>) {
  const [input, setInput] = useAtom(inputAtom);
  return <input spellCheck="true" className={twMerge("border border-gray-400 p-1 w-full", className)} value={input} onInput={e => {
    setInput((e.target as HTMLInputElement).value);
  }} onKeyDown={e => {
    if (onEscape) {
      if (e.key === "Escape") {
        e.preventDefault();
        e.stopPropagation();
        onEscape();
      }
    }
    if (onEnter) {
      if (e.key === "Enter" && !e.shiftKey) {
        e.preventDefault();
        e.stopPropagation();
        onEnter();
      }
    }
  }} {...props} data-sentry-component="TextInput" data-sentry-source-file="input.tsx" />;
}
export function ExpandableTextArea({
  atom: inputAtom,
  onEscape,
  onEnter,
  maxHeight = 200,
  className,
  ...props
}: {
  atom: WritableAtom<string, [string], void>;
  onEscape?: () => void;
  onEnter?: () => void;
  /** Max height (in px) before scrolling kicks in. */
  maxHeight?: number;
} & React.TextareaHTMLAttributes<HTMLTextAreaElement>) {
  const [input, setInput] = useAtom(inputAtom);
  const [firstRender, setFirstRender] = useState(true);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const autoResize = useCallback(() => {
    if (!textAreaRef.current) {
      return;
    }
    textAreaRef.current.style.height = "auto";
    const newHeight = Math.min(textAreaRef.current.scrollHeight, maxHeight);
    textAreaRef.current.style.height = `${newHeight}px`;
    textAreaRef.current.style.overflowY = textAreaRef.current.scrollHeight > maxHeight ? "auto" : "hidden";
  }, [maxHeight]);
  useEffect(() => {
    if (firstRender) {
      setFirstRender(false);
      autoResize();
    }
  }, [textAreaRef.current]);
  return <textarea spellCheck="true" enterKeyHint={onEnter ? "send" : undefined} ref={textAreaRef} className={twMerge("border border-gray-400 p-1 w-full resize-none", className)} value={input} onInput={e => {
    setInput((e.target as HTMLTextAreaElement).value);
    autoResize();
  }} onKeyDown={e => {
    if (e.key === "Escape" && onEscape) {
      e.preventDefault();
      e.stopPropagation();
      onEscape();
    }
    if (e.key === "Enter" && !e.shiftKey && onEnter) {
      e.preventDefault();
      e.stopPropagation();
      onEnter();
    }
  }} {...props} data-sentry-component="ExpandableTextArea" data-sentry-source-file="input.tsx" />;
}
export function EditableText({
  atom: inputAtom,
  emptyPlaceholder,
  placeholder,
  className,
  textarea,
  markdown,
  children
}: {
  atom: WritableAtom<string, [string], void>;
  emptyPlaceholder?: string;
  placeholder?: string;
  className?: string;
  textarea?: boolean;
  markdown?: boolean;
  children?: React.ReactNode;
}) {
  const [editing, setEditing] = useState(false);
  const [halfEditing, setHalfEditing] = useState(false);
  const [inputValue] = useAtom(inputAtom);
  const editingValueAtom = useMemo(() => {
    const store = getDefaultStore();
    const initValue = store.get(inputAtom);
    return atom(initValue);
  }, [inputAtom]);
  function onEnter() {
    const store = getDefaultStore();
    const editingValue = store.get(editingValueAtom);
    store.set(inputAtom, editingValue);
    setHalfEditing(false);
    setEditing(false);
  }
  function onEscape() {
    setHalfEditing(false);
    setEditing(false);
  }
  if (editing && textarea) {
    return <ExpandableTextArea atom={editingValueAtom} onEnter={onEnter} onEscape={onEscape} autoFocus placeholder={placeholder} />;
  }
  if (editing) {
    return <TextInput atom={editingValueAtom} onEnter={onEnter} onEscape={onEscape} autoFocus placeholder={placeholder} />;
  }
  const text = inputValue || emptyPlaceholder || "(empty)";
  let innerContent: React.ReactNode;
  if (markdown) {
    innerContent = <Markdown className={twMerge("inline-block", inputValue ? "" : "opacity-50", halfEditing ? "bg-yellow-100" : "")}>
        {text}
      </Markdown>;
  } else {
    innerContent = <span className={halfEditing ? "bg-yellow-100" : ""}>
        {children || text}
      </span>;
  }
  return <span className={twMerge("cursor-pointer", className)} onClick={event => {
    if (event.detail > 1) {
      const store = getDefaultStore();
      store.set(editingValueAtom, store.get(inputAtom));
      setHalfEditing(false);
      setEditing(true);
    } else {
      setHalfEditing(!halfEditing);
      setTimeout(() => {
        setHalfEditing(false);
      }, 1000);
    }
  }} data-sentry-component="EditableText" data-sentry-source-file="input.tsx">
      {innerContent}
    </span>;
}
export function Checkbox({
  atom: inputAtom,
  className
}: {
  atom: WritableAtom<boolean, [boolean], void>;
  className?: string;
}) {
  const [input, setInput] = useAtom(inputAtom);
  return <input type="checkbox" className={className} checked={input} onChange={event => {
    setInput(event.target.checked);
  }} data-sentry-component="Checkbox" data-sentry-source-file="input.tsx" />;
}