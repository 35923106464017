import { atom, Atom, getDefaultStore, WritableAtom } from "jotai/vanilla";
import { createJSONStorage, loadable, RESET } from "jotai/utils";
import type { Loadable } from "jotai/vanilla/utils/loadable";
import { Getter } from "jotai/vanilla";
import React from "react";
import { useAtomValue } from "jotai/react";

export class LoadReset<T> {
  triggerAtom: WritableAtom<number, [number], void>;
  atom: Atom<Promise<T> | T>;
  loadedValue: Atom<T | null>;
  loadable: Atom<Loadable<Promise<T>>>;
  overwriteAtom: WritableAtom<T | null, [T | null], void>;
  constructor(public loadFunc: (get: Getter) => Promise<T>) {
    this.loadFunc = loadFunc;
    this.triggerAtom = atom(0);
    this.overwriteAtom = atom<T | null>(null);
    this.atom = atom(async (get) => {
      get(this.triggerAtom);
      const overwrite = get(this.overwriteAtom);
      if (overwrite !== null) {
        return overwrite;
      }
      const result = await promiseUntilDeffered(this.loadFunc(get));
      return result;
    });
    this.loadedValue = atom((get) => {
      const l = get(this.loadable);
      if (l.state === "hasData") {
        return l.data;
      }
      return null;
    });
    this.loadable = loadable(this.atom);
  }

  refresh() {
    const store = getDefaultStore();
    store.set(this.triggerAtom, store.get(this.triggerAtom) + 1);
    store.set(this.overwriteAtom, null);
  }

  useProgress():
    | { status: "done"; data: T }
    | { status: "working"; component: React.ReactNode } {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const l = useAtomValue(this.loadable);
    if (l.state === "loading") {
      return {
        status: "working",
        component: React.createElement("div", null, "..."),
      };
    }
    if (l.state === "hasError") {
      return {
        status: "working",
        component: React.createElement(
          "div",
          null,
          `Error loading: ${(l.error as any)?.message}`
        ),
      };
    }
    return { status: "done", data: l.data };
  }

  override(value: T) {
    const store = getDefaultStore();
    store.set(this.overwriteAtom, value);
  }

  async get(): Promise<T> {
    const store = getDefaultStore();
    return store.get(this.atom);
  }
}

const defaultStorage = createJSONStorage();

let _deferredInit: (() => void)[] | null = [];

export function promiseUntilDeffered<T>(promise: Promise<T>) {
  if (_deferredInit === null) {
    return promise;
  }
  return new Promise<T>((resolve, reject) => {
    _deferredInit!.push(() => {
      promise.then(resolve, reject);
    });
  });
}

export function initDeferredAtom() {
  if (!_deferredInit) {
    return;
  }
  for (const fn of _deferredInit) {
    fn();
  }
  _deferredInit = null;
}

export function deferredAtomWithStorage<T>(
  key: string,
  initialValue: T,
  storage = defaultStorage
) {
  const baseAtom = atom(initialValue);
  if (_deferredInit === null) {
    console.warn(
      "deferredAtomWithStorage should be called before initDeferredAtom"
    );
  }
  baseAtom.onMount = (setAtom) => {
    let unsub: () => void;
    const unsubscriber = () => {
      if (unsub) {
        unsub();
      }
    };
    if (!_deferredInit) {
      let unsub;
      setAtom(storage.getItem(key, initialValue) as T);
      if (storage.subscribe) {
        unsub = storage.subscribe(
          key,
          setAtom as (value: unknown) => void,
          initialValue
        );
      }
      return unsub;
    }
    _deferredInit.push(() => {
      setAtom(storage.getItem(key, initialValue) as T);
      if (storage.subscribe) {
        unsub = storage.subscribe(
          key,
          setAtom as (value: unknown) => void,
          initialValue
        );
      }
    });
    return unsubscriber;
  };
  const anAtom = atom(
    (get) => get(baseAtom),
    (get, set, update) => {
      const nextValue =
        typeof update === "function" ? update(get(baseAtom)) : update;
      if (nextValue === RESET) {
        set(baseAtom, initialValue);
        return storage.removeItem(key);
      }
      if (nextValue instanceof Promise) {
        return nextValue.then((resolvedValue) => {
          set(baseAtom, resolvedValue);
          return storage.setItem(key, resolvedValue);
        });
      }
      set(baseAtom, nextValue);
      return storage.setItem(key, nextValue);
    }
  );
  return anAtom as WritableAtom<T, [T], void>;
}
