"use client";

import React from "react";
import { AppPage } from "@/components/apppage";
import { SignedIn, SignedOut } from "@clerk/nextjs";
export default function LandingPage() {
  return <AppPage title="Memory Atlas" rightHeader={<>
          <SignedIn>
            <a href="/db" className="bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700 transition-colors mr-4">
              Access Database
            </a>
          </SignedIn>
          <SignedOut>
            <a href="/start" className="bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700 transition-colors">
              Learn More / Get Started
            </a>
          </SignedOut>
        </>} data-sentry-element="AppPage" data-sentry-component="LandingPage" data-sentry-source-file="page.tsx">
      <div className="mb-8">
        <img src="/favicon.ico" alt="Memory Atlas Icon" className="w-48 h-48 mx-auto" />
        <h2 className="text-4xl font-extrabold mt-4">
          Catalog, organize, and search your belongings
        </h2>
        <p className="text-lg mt-2 text-gray-700">
          ... into an AI-powered database using your voice
        </p>
      </div>

      {/* Embedded YouTube Video */}
      <div className="w-full max-w-2xl aspect-video mb-8">
        <iframe className="w-full h-full rounded-lg border" src="https://www.youtube.com/embed/xjJRB9i9220" title="YouTube video" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
      </div>

      {/* How it Works Section */}
      <div className="max-w-3xl text-left">
        <h3 className="text-2xl font-bold mb-4">How It Works</h3>
        <ul className="list-disc pl-8 space-y-2 text-gray-700">
          <li>Speak directly into the app to describe your belongings.</li>
          <li>Automatically categorize items into a smart database.</li>
          <li>
            Search and ask questions about your belongings with natural language
            commands.
          </li>
          <li>Get insights, organize efficiently, and never lose track!</li>
        </ul>
      </div>
    </AppPage>;
}