"use client";

import { ReactNode, useEffect, useState } from "react";
import AuthMenu from "./authmenu";
import { CameraIcon, CodeBracketIcon, NumberedListIcon } from "@heroicons/react/16/solid";
import { twMerge } from "tailwind-merge";
import { deferredAtomWithStorage } from "./loader";
import { VoiceSelection } from "./icons";
import { CheckButton } from "./input";
export const useSpeechAtom = deferredAtomWithStorage("useSpeech", false);
export const debugModeAtom = deferredAtomWithStorage("debugMode", false);
export function AppPage({
  title,
  rightHeader,
  wide,
  fullHeight,
  noFooter,
  children
}: {
  title: ReactNode;
  rightHeader?: ReactNode;
  wide?: boolean;
  fullHeight?: boolean;
  noFooter?: boolean;
  children: ReactNode;
}) {
  const [year, setYear] = useState(2025);
  useEffect(() => {
    if (typeof window !== "undefined") {
      setYear(new Date().getFullYear());
    }
  }, []);
  return <div className={twMerge("min-h-screen", fullHeight ? "h-screen" : "")} data-sentry-component="AppPage" data-sentry-source-file="apppage.tsx">
      <header className="w-full fixed flex items-center justify-between px-2 sm:px-8 py-1 sm:py-2 border-b h-8 sm:h-12 bg-gray-100 z-10 shadow-md">
        <div className="flex items-center justify-between space-x-4">
          <a href="/">
            <img src="/favicon.ico" alt="Memory Atlas Icon" className="w-4 h-4 sm:w-10 sm:h-10" />
          </a>
          <h1 className="text-sm sm:text-2xl font-bold">{title}</h1>
        </div>
        <div>
          <CheckButton atom={debugModeAtom} on={<CodeBracketIcon className="h-3 w-3 sm:h-4 sm:w-4" />} off={<CodeBracketIcon className="h-3 w-3 sm:h-4 sm:w-4" />} className="rounded-md border-none bg-inherit hover:text-blue-600 hover:bg-inherit px-2" onClass="" offClass="opacity-25" data-sentry-element="CheckButton" data-sentry-source-file="apppage.tsx" />
          <CheckButton atom={useSpeechAtom} on={<VoiceSelection className="h-3 w-3 sm:h-4 sm:w-4" />} off={<VoiceSelection className="h-3 h-3 sm:h-4 sm:w-4" />} className="rounded-md border-none bg-inherit hover:text-blue-600 hover:bg-inherit px-2" onClass="" offClass="opacity-25" data-sentry-element="CheckButton" data-sentry-source-file="apppage.tsx" />
          {rightHeader}
          <a href="/capture" className="bg-white rounded-full inline-block mr-2 p-1 border hover:bg-blue-100">
            <CameraIcon className="h-3 w-3 sm:h-6 sm:w-6" data-sentry-element="CameraIcon" data-sentry-source-file="apppage.tsx" />
          </a>
          <a href="/todo" className="bg-white rounded-full inline-block mr-2 p-1 border hover:bg-blue-100">
            <NumberedListIcon className="h-3 w-3 sm:h-6 sm:w-6" data-sentry-element="NumberedListIcon" data-sentry-source-file="apppage.tsx" />
          </a>
          <AuthMenu data-sentry-element="AuthMenu" data-sentry-source-file="apppage.tsx" />
        </div>
      </header>

      <main className={twMerge("pt-12 px-8 space-y-8", wide ? "" : "max-w-4xl mx-auto", fullHeight ? "h-full" : "")}>
        {children}
      </main>

      {!noFooter && <footer className="w-full py-4 text-center border-t text-gray-500 text-sm">
          &copy; {year} Ian Bicking. All rights reserved.
        </footer>}
    </div>;
}